<template>
  <div id="staff-list">
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn
          fab
          dark
          small
          color="indigo"
          @click.prevent="addRecordPrompt = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="titlePreviewRecordPrompt" persistent max-width="75%">
      <new-lo-title-preview
        :pageTitle="titlePreviewRecordPageTitle"
        :previewFlag="titlePreviewFlag"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hideTitlePreviewRecordPrompt"
        @hideEditRecordPrompt="hideTitleEditRecordPrompt"
        v-if="titlePreviewRecordPrompt"
      ></new-lo-title-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle
        class="text-h5 d-flex align-center justify-space-between"
      >
        <h4 class="mb-0">{{ PageDescription }}</h4>
        <v-btn
          medium
          @click.prevent="refreshPageData"
          color="#a4c639"
          elevation="30"
          rounded
          class="font-size-h6 mr-3 my-3 white--text"
          >&nbsp; Refresh &nbsp;
        </v-btn>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>

        <v-container class="py-0" v-if="SearchFormFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" md="3">
                <label>
                  <h6>Payment Type <small>(Optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="PaymentTypeOptions"
                  :rules="PaymentTypeRules"
                  v-model="PaymentType"
                  required
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>
                  <h6><span class="text-danger">*</span> From Date</h6>
                </label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      label="From Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FromDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>
                  <h6><span class="text-danger">*</span> To Date</h6>
                </label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      label="To Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ToDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>
                  <h6>
                    Zone
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  hint="Select zone"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm(2)"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container class="py-0" v-if="ResultFlag && rows.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="rows.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ rows.length }} records found.</h3>
              <p></p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="ExcelDownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    rows,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                ><v-icon dark> mdi-download </v-icon> Download</v-btn
              >
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :show-select="FloatingButtonOptions.ShowSelectFlag"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="rows"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.ReceiptPath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.ReceiptPath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-pdf </v-icon>
                      </v-btn>
                    </template>
                    <span> Receipt Download </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.ApplicationPath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.ApplicationPath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-download </v-icon>
                      </v-btn>
                    </template>
                    <span> Application Download </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.PreviewFlag != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="titlePreviewData(item, 1)"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="warning"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-search </v-icon>
                      </v-btn>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.TitleConfirmationFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="titlePreviewData(item, 3)"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-check </v-icon>
                      </v-btn>
                    </template>
                    <span> Confirmation </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="item.TitleApprovalFlag || item.PaymentApprovalFlag"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="titlePreviewData(item, 2)"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-file-check </v-icon>
                      </v-btn>
                    </template>
                    <span> Approve </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import NewLoTitlePreview from "@/view/pages/erp/new_lo_title/NewLoTitlePreview.vue";

export default {
  mixins: [common],
  components: {
    NewLoTitlePreview,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,
      SearchFormFlag: false,
      DownloadFlag: true,

      rows: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      TableLoadingFlag: false,

      SpeedDialFlag: false,
      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,

      titlePreviewRecordPrompt: false,
      titlePreviewRecordPageTitle: "",
      titlePreviewFlag: false,
      selectedData: {},

      ExcelDownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      PaymentTypeRules: [],
      PaymentType: "",
      PaymentTypeOptions: [
        { value: "", text: "--Select Payment Type--" },
        { value: 1, text: "New LO Title" },
        { value: 2, text: "New LO Payment" },
      ],
      PaymentTypeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        console.log("RoleId=" + RoleId);

        if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
          this.SearchFormFlag = true;
          this.getZoneCodeOptions();
        } else {
          var ZoneCode = this.$session.get("ZoneId");
          ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
          console.log("ZoneCode=" + ZoneCode);
          this.ZoneCode = ZoneCode;
          this.searchForm(3);
        }
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.getTableRecords();
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "new_lo_title",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideTitleEditRecordPrompt() {
      console.log("hideTitleEditRecordPrompt called");
      this.titlePreviewRecordPrompt = false;
      var RoleId = this.$session.get("RoleId");
      RoleId = RoleId == (null || undefined) ? "" : RoleId;
      console.log("RoleId=" + RoleId);

      if (RoleId == 1 || RoleId == 2 || RoleId == 4) {
        this.searchForm(2);
      } else {
        this.searchForm(3);
      }
    },
    hideTitlePreviewRecordPrompt() {
      console.log("hideTitlePreviewRecordPrompt called");
      this.titlePreviewRecordPrompt = false;
    },
    titlePreviewData(tr, type) {
      console.log("titlePreviewData called");
      console.log({ type });
      var OrderType = tr.OrdersType;
      console.log({ OrderType });
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var pageTitle = "";
        var previewFlag = false;
        if (type == 1) {
          previewFlag = true; //
          pageTitle =
            OrderType == 3
              ? "Preview New LO Title Details"
              : "Preview New LO Payment Details";
        } else if (type == 2) {
          pageTitle =
            OrderType == 3
              ? "Approve New LO Title Details"
              : "Approve New LO Payment Details";
        } else {
          pageTitle =
            OrderType == 3
              ? "Confirmation New LO Title Details"
              : "Confirmation New LO Payment Details";
        }
        this.titlePreviewRecordPrompt = true;
        this.titlePreviewFlag = previewFlag;
        this.titlePreviewRecordPageTitle = pageTitle;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      console.log("CurrentYearId=" + this.CurrentYearId);
      this.LoadingFlag = true;
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        YearCode: this.CurrentYearId,
        NotAvailable: 1,
        AllZones: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm(status) {
      console.log("searchForm is called");

      var validate1 = true;
      if (status != 3) {
        validate1 = this.$refs.form2.validate();
        console.log("validate1=" + validate1);
      }

      var FromDate = this.FromDate;
      var ToDate = this.ToDate;

      if ((validate1 && FromDate != "" && ToDate != "") || status == 3) {
        // this.SearchFlag = true;
        this.LoadingFlag = true;
        this.rows = [];
        this.ExcelFileName = "";
        this.ExcelDownloadFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/new-lom/lists";
        var upload = {
          UserInterface: status,
          Zone: this.ZoneCode,
          FromDate: FromDate,
          ToDate: ToDate,
          PaymentType: this.PaymentType,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.LoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.table_content;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.rows = records.TableData;

              thisIns.ExcelDownloadFlag = options.ExcelDownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    this.FromDate = todayDate;
    this.ToDate = todayDate;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#staff-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
}
</style>